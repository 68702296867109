import { IResponseException } from '@adtelligent-fe/react-common/src/typings/data';
import { action } from 'typesafe-actions';

import {
  AuthActionTypes,
  IForgotPasswordFormDto,
  IResetPasswordFormDto,
  ISignInFormDto,
  ISignUpFormDto
} from './types';

export const initSignInFormAction = () => action(AuthActionTypes.InitSignInForm);
export const signInAction = (data: ISignInFormDto) => action(AuthActionTypes.SignIn, data);
export const signInSuccessAction = () => action(AuthActionTypes.SignInSuccess);
export const signInFailureAction = (errorMsg: IResponseException) =>
  action(AuthActionTypes.SignInFailure, errorMsg, { message: errorMsg }, true);
export const initSignUpFormAction = () => action(AuthActionTypes.InitSignUpForm);
export const signUpAction = (data: ISignUpFormDto) => action(AuthActionTypes.SignUp, data);
export const signUpSuccessAction = () => action(AuthActionTypes.SignUpSuccess);
export const signUpFailureAction = (errorMsg: IResponseException) => action(AuthActionTypes.SignUpFailure, errorMsg);
export const initForgotPasswordFormAction = () => action(AuthActionTypes.InitForgotPasswordForm);
export const forgotPasswordAction = (data: IForgotPasswordFormDto) => action(AuthActionTypes.ForgotPassword, data);
export const forgotPasswordSuccessAction = () => action(AuthActionTypes.ForgotPasswordSuccess);
export const forgotPasswordFailureAction = (errorMsg: IResponseException) =>
  action(AuthActionTypes.ForgotPasswordFailure, errorMsg);
export const resetPasswordAction = (data: IResetPasswordFormDto) => action(AuthActionTypes.ResetPassword, data);
export const resetPasswordSuccessAction = () => action(AuthActionTypes.ResetPasswordSuccess);
export const resetPasswordFailureAction = (errorMsg: IResponseException) =>
  action(AuthActionTypes.ResetPasswordFailure, errorMsg);
export const logoutAction = () => action(AuthActionTypes.Logout);
export const signInBySessionAction = (sessionId: string) => action(AuthActionTypes.SignInBySession, sessionId);
