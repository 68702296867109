import { ICommonState } from '@adtelligent-fe/react-common/src/store/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { UserShareAccessTypes } from '../components/UserShareAccess';
import { ArticleSlice } from '../modules/ai-video/modules/articles/articles.slice';
import { ISitesState } from '../modules/ai-video/modules/sites/sites.slice';
import { IVideoTemplateState } from '../modules/ai-video/modules/video-template/video-template.slice';
import { AppTypes } from '../modules/app';
import { ClientChannelsTypes } from '../modules/clientChannels';
import { IClientsSlice } from '../modules/clients/clients.slice';
import { IEntityAttributeValueSlice } from '../modules/global-settings/modules/entity-attribute-value/entity-attribute-value.slice';
import { IMlAdunitLinksState } from '../modules/ml-adunits-list/ml-adunits.slice';
import { PaymentTypes } from '../modules/payment';
import { IPublishersSlice } from '../modules/publishers/publishers.slice';
import { ISellersSlice } from '../modules/sellers/sellers.slice';
import { ManageAccessTypes } from '../modules/settings/modules/manageAccess';
import { MarketplaceSettingsTypes } from '../modules/settings/modules/marketplaceSettings';
import { MyAccountsTypes } from '../modules/settings/modules/myAccounts';
import { PaymentInfoTypes } from '../modules/settings/modules/paymentInfo';
import { PlatformSettingsTypes } from '../modules/settings/modules/platformSettings';
import { ProfileTypes } from '../modules/settings/modules/profile';
import { VpaidDeliveryTypes } from '../modules/settings/modules/vpaidDelivery';
import { WlTypes } from '../modules/settings/modules/whiteLabelSettings';
import { WLStylingActionTypes } from '../modules/settings/modules/whiteLabelStyling';
import { IHBMPSitesSlice } from '../modules/sites/sites.slice';

export interface IRootState extends ICommonState {
  app: AppTypes.IAppState;
  platformSettings: PlatformSettingsTypes.IPlatformSettingsState;
  paymentInfo: PaymentInfoTypes.IPaymentInfoState;
  wlStyling: WLStylingActionTypes.IWLStylingState;
  manageAccess: ManageAccessTypes.IManageAccessState;
  myAccounts: MyAccountsTypes.IMyAccountsState;
  wlSettings: WlTypes.IWlSettingsState;
  marketplaceSettings: MarketplaceSettingsTypes.IMarketplaceSettingsStateType;
  payment: PaymentTypes.IPaymentState;
  profile: ProfileTypes.IProfileState;
  vpaidDelivery: VpaidDeliveryTypes.IVpaidDeliveryState;
  clientChannels: ClientChannelsTypes.IClientChannelsState;
  userShareAccess: UserShareAccessTypes.IUserShareAccessState;
}

export interface IHBAdminState extends ICommonState {
  mlAdunitsSlice: IMlAdunitLinksState;
  clientsSlice: IClientsSlice;
  publishersSlice: IPublishersSlice;
  hbmpSitesSlice: IHBMPSitesSlice;
  entityAttributeValueSlice: IEntityAttributeValueSlice;
  sellersSlice: ISellersSlice;
  articlesSlice: ArticleSlice;
  sitesSlice: ISitesState;
  videoTemplateSlice: IVideoTemplateState;
}
export const useHBSelector: TypedUseSelectorHook<IHBAdminState> = useSelector;
