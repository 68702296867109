import { Sidebar } from '@adtelligent-fe/react-common/src/components/Sidebar';
import { MenuItem } from 'primereact/menuitem';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { HeaderMenu } from '../header-menu';
import styles from './ProtectedLayout.module.scss';

interface IProtectedLayoutProps {
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

export const ProtectedLayout: FC<IProtectedLayoutProps> = ({
  contentLayout: ContentLayout,
  sidebarItems
}) => {
  return (
    <div className={styles.wrapper}>
      <HeaderMenu />

      <main
        style={{ height: 'calc(100% - 60px)' }}
        className={'flex-grow-1 d-flex'}
      >
        {sidebarItems && <Sidebar items={sidebarItems} />}
        <section className={'w-100 overflow-auto p-4'}>
          {ContentLayout ? <ContentLayout /> : <Outlet />}
        </section>
      </main>
    </div>
  );
};
