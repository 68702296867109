import { PAGINATION } from '@adtelligent-fe/react-common/src/constants/PAGINATION';
import { createSlice } from '@reduxjs/toolkit';

import { IDefaultGridFilterData } from './ml-adunits.types';

export interface IMlAdunitLinksState {
  filterData: IDefaultGridFilterData;
}

export const initialMlAdunitLinksState: IMlAdunitLinksState = {
  filterData: {
    page: 1,
    rows: PAGINATION.PAGE_SIZE,
    first: 0,
    sortField: '',
    sortOrder: 1
  }
};

const mlAdunitLinksSlice = createSlice({
  name: 'mlAdunitsSlice',
  initialState: initialMlAdunitLinksState,
  reducers: {
    setFilterData: (state, action) => {
      state.filterData = { ...state.filterData, ...action.payload };
    }
  }
});

export default mlAdunitLinksSlice.reducer;

export const { setFilterData } = mlAdunitLinksSlice.actions;
