import './i18n';
import './assets/styles/index.scss';

// import { AppMetaData } from '@adtelligent-fe/react-common/src/components/AppMetaData';
// import { APP_NAME } from '@adtelligent-fe/react-common/src/constants/APP_NAME';
import reportWebVitals from '@adtelligent-fe/react-common/src/reportWebVitals';
import * as serviceWorkerRegistration from '@adtelligent-fe/react-common/src/serviceWorkerRegistration';
import store, { history } from '@adtelligent-fe/react-common/src/store';
// import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { App } from './modules/app';

// if (import.meta.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     release: `${APP_NAME.SSP_MEMBER_ZONE}@${VERSION.package}`
//   });
// }
// setCookie('env', 'prod');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      {/*<AppMetaData />*/}
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
