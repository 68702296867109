import translationRU from '@adtelligent-fe/react-common/src/i18n/dictionaries/ru';

export default {
  ...translationRU,
  'Profile Settings': '',
  'Account Settings': '',
  Name: '',
  Country: '',
  Email: '',
  Timezone: '',
  'Terms and Conditions': '',
  'Terms of Service Effective date': '',
  'Review Terms & Conditions': '',
  'Authentification Settings': '',
  Save: '',
  'Payment Information': '',
  Lookup: '',
  Search: '',
  Create: '',
  'Payout processor': '',
  Info: '',
  'Date created': '',
  Default: '',
  'Use by default': '',
  'Add new payment method': '',
  'View payment method #{{id}}': '',
  'Payment system': '',
  Title: '',
  'I confirm that the payment details on this form are correct': '',
  'Color Scheme': '',
  Copied: '',
  Ok: '',
  Accept: '',
  Close: '',
  'It appears your Web browser is not configured to display PDF files. No worries, just': '',
  'click here to download the PDF file': '',
  'Recovery password link': '',
  Currency: '',
  'Authentication Settings': '',
  'All changes have been successfully saved': '',
  'Should be more than 2 symbols': '',
  'Should be less than 48 symbols': '',
  'Incorrect domain format': '',
  'Field is required': '',
  'To set up sub domain/custom domain feature, please fill in the form below and your manager will help you with the further adjustments':
    '',
  'Please enter the desired subdomain': '',
  'Your dashboard URL will look like': '',
  'Your VAST URL will look like': '',
  'Your Wildcard SSL certificate should be issued for the following FQDN': '',
  'Please purchase an SSL certificate from any Certificate Authority and ask for their assistance with activation. In most cases the vendor you purchase SSL certificate from guides you through SSL activation process and sends .crt file along with the private key(RSA key)':
    '',
  'If your SSL provider is asking for a CSR code to proceed with activation, please mention this in the message form below before you clicked "Send".We will get back to you with further instructions in ticket.':
    '',
  Message: '',
  'Login page logo': '',
  'Logo preview': '',
  'Only for custom domain': '',
  'Login page logo preview': '',
  'Select image': '',
  'Account number': '',
  'Payment purpose': '',
  'Wiretransfer processor': '',
  'Payee name': '',
  'Payee nickname': '',
  'Payee address': '',
  'Payee bank account number': '',
  'Payee bank routing number': '',
  'Payee bank name': '',
  'Beneficiary company name': '',
  'Beneficiary company address': '',
  'Beneficiary bank account (IBAN)': '',
  'Bank name': '',
  'Bank address': '',
  'Additional information': '',
  'Created successfully': '',
  'Successfully installed by default': '',
  'Сannot be set by default': '',
  Item: '',
  'PALLETE GENERATOR': '',
  'Upload image': '',
  'PREVIEW UI ELEMENTS': '',
  Buttons: '',
  Secondary: '',
  Primary: '',
  Dropdown: '',
  Link: '',
  Inputs: '',
  'Input text': '',
  Textarea: '',
  'Date picker': '',
  Select: '',
  Multiselect: '',
  'Hint text': '',
  Checkbox: '',
  Checked: '',
  Unchecked: '',
  'Radio Buttons': '',
  Switch: '',
  NO: '',
  YES: '',
  Slider: '',
  Progress: '',
  Tabs: '',
  'Content tab': '',
  Grid: '',
  'Value must be greater than': '',
  'Value must be lower than': '',
  'Value must be equal or lower than': '',
  'VPAID delivery settings': '',
  'New Channel': '',
  'You are about to export this table to CSV.': '',
  'Columns that you are selected in Table Settings are going to export.': '',
  'If you want to add or remove some columns, please go to the Table Settings.': '',
  'Archive Channels': '',
  'You are about to archive {{amount}} channels.': '',
  "Archive channels can't be restored after 90 days.": '',
  Channel: '',
  'All Channels': '',
  'Global Search': '',
  'Filter Table': '',
  'Table Settings': '',
  'Showing {first} to {last} of {totalRecords}': '',
  'Platform Settings': '',
  'Use custom footer': '',
  clear: '',
  'First Name': '',
  'Last Name': '',
  Telegram: '',
  Skype: '',
  'Privacy Policy': '',
  'Cookie Policy': '',
  'Select file': '',
  'Custom markup': '',
  'Global block list by App names': '',
  'Type something...': '',
  'Global block list by Bundle IDs': '',
  'Global block list by Domains': '',
  'Global block list by IP': '',
  'Default Passback tag': '',
  'Global Block Lists': '',
  '3rd party tracker': '',
  'Event type': '',
  'Traffic type': '',
  Environment: '',
  'Traffic score': '',
  URL: '',
  Tracker: '',
  'Basic Settings': '',
  'Advanced Settings': '',
  'API Access': '',
  Summary: '',
  'Channel Name': '',
  'Channel Revshare': '',
  'Share of revenue is ignored when another pricing model is selected in the source settings. You can easily override channel revshare in the source settings by using the override option or by selecting a different pricing model (e.g. fixed CPM).':
    '',
  'Channel Dscription': '',
  Next: '',
  Exit: '',
  Back: '',
  "You are leaving the flow and all your changes won't be saved.": '',
  'Are you sure you want to proceed?': '',
  'Save & Next': '',
  'Global Block List': '',
  'Select App Names': '',
  'Select Bundle IDs': '',
  'Select Domains': '',
  'Select SubIDs': '',
  'Schain Object Behavior': '',
  'This option provides access to the statistics through XML link. Press the button to download statistics API. Please note, in order to pull the data for your account via API reporting, you need to replace':
    '',
  'stated in document with your member-zone URL.': '',
  'API Manual': '',
  'Refresh hash code': '',
  Copy: '',
  'Advanced Settings - Global Block List': '',
  'Advanced Settings - Tittle': '',
  'Email Permissions': '',
  'Send Permissions': '',
  'Share Access': '',
  'Access Provider': '',
  'Are you sure you want to revoke access for {{username}}?': '',
  Confirmation: '',
  'Add New Access': '',
  'Enter an email address in order to give the access to this person.': '',
  'Select a Status': '',
  Pause: '',
  Archive: '',
  'Publishers Performance Over Period': ''
};
