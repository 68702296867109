import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRouteWithLayout } from '../../components/ProtectedRoute';

const SitesListPage = React.lazy(() => import('./pages/sites-list.page'));
const CreateSitePage = React.lazy(() => import('./pages/create-site.page'));
const EditSitePage = React.lazy(() => import('./pages/edit-site.page'));

const SitesPage = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<ProtectedRouteWithLayout />}>
        <Route path={'create'} element={<CreateSitePage />} />
        <Route path={':id/edit'} element={<EditSitePage />} />
        <Route index element={<SitesListPage />} />
      </Route>
    </Routes>
  );
};

export default SitesPage;
