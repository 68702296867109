export const HBMP_SITES_LOOKUP_FIELDS = 'id,domain';
export const HBMP_SITES_FIELDS =
  'id,domain,refresh_config,iab_category,client,client.id,client.name,iab_category_dictionary_item.name,bsid,is_mobile_refresh_logic_enabled,is_enabled_protected_media_tag,is_enabled_pixalate_tag,is_enabled_moat_tag,scorings';
export const HBMP_SITES_EXPAND_FIELDS = 'iab_category_dictionary_item,client,refresh_configs,scorings';
export const HBMP_SITE_EXPAND_FIELDS =
  'network,adstxt,client,blocked_categories,refresh_configs,blocked_advertisers_domains';

export const CURRENCY = {
  USD: 'USD',
  UAH: 'UAH'
};

export const GRANULARITY = {
  CUSTOM_TYPE: 'custom',
  DEFAULT_GRANULE: [
    {
      min: 0,
      max: 5,
      step: 0.01
    }
  ],
  UAH: [
    {
      min: 0,
      max: 5,
      step: 0.01
    },
    {
      min: 5,
      max: 10,
      step: 0.05
    },
    {
      min: 10,
      max: 20,
      step: 0.15
    },
    {
      min: 20,
      max: 40,
      step: 0.25
    },
    {
      min: 40,
      max: 80,
      step: 0.5
    },
    {
      min: 80,
      max: 160,
      step: 1
    }
  ]
};

export const TEST_MODEL = {
  OURS: {
    CODE: '0',
    LANG_CODE: 'live-data'
  },
  CLIENTS: {
    CODE: '1',
    LANG_CODE: 'test-data'
  }
};

export const adminSettingsFieldNames = {
  startAuctionOn: 'refresh_configs.auctionStartAt',
  startAuctionMobileOn: 'refresh_configs.mobile_refresh_on',
  skipInitialAuction: 'skip_initial_auction',
  allow1x1: 'is_allow_1x1',
  strictSizeMatching: 'is_enabled_strict_size_matching',
  singleDisplayMode: 'is_enabled_single_display_mode',
  advancedRefreshTimer: 'is_enabled_advanced_refresh_timer',
  throttleDuplicatedDisplay: 'is_enabled_throttle_duplicated',
  overrideDomain: 'override_domain',
  showBidmaticLogo: 'is_show_bidmatic_logo',
  adxEnhance: 'adx_enhance',
  excludeFromGeoEdge: 'is_enabled_exclude_from_geoedge',
  refreshImr: 'is_enabled_refresh_imr',
  customMobileRefresh: 'refresh_configs.is_mobile_refresh_logic_enabled',
  desktopCustom: {
    refreshInterval: 'refresh_configs.refreshHBWinnerInterval',
    subsequentRefreshInterval: 'refresh_configs.refreshSubsequentInterval',
    refreshWhenWinnerIs: 'refresh_configs.refreshWhenWinnerIs',
    buyersInRefresh: 'refresh_configs.buyersInRefresh'
  },
  mobileCustom: {
    refreshInterval: 'refresh_configs.mobile_refresh_interval',
    subsequentRefreshInterval: 'refresh_configs.mobile_subsequent_refresh_interval',
    refreshWhenWinnerIs: 'refresh_configs.mobile_refresh_when_buyer_is',
    buyersInRefresh: 'refresh_configs.mobile_buyers_in_refresh'
  },

  adunitsExcludedFromRefresh: 'refresh_configs.adunits_excluded_from_refresh',
  hbCreativeIds: 'hb_creative_ids',
  clientDirectLineItems: 'client_direct_line_items',
  updateTime: 'update_time'
} as const;

export const brandSafetySettingsFieldNames = {
  blocked_advertisers: 'blocked_advertisers_domains',
  blocked_categories: 'blocked_categories'
} as const;
