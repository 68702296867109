import { WHITELABEL_THEME_SCHEMA } from '@adtelligent-fe/react-common/src/constants/WHITELABEL_THEME_SCHEMA';

const ROOT_ELEMENT = document.documentElement;

export type CustomThemeKeys =
  | 'header_bg_color'
  | 'sidebar_bg_color'
  | 'footer_bg_color'
  | 'primary'
  | 'btn_default_bg_color'
  | 'secondary'
  | 'body-bg'
  | 'link_color';

export type CustomTheme = {
  r: number;
  g: number;
  b: number;
};

export const THEME_TYPES = {
  BASIC: {
    ID: 'basic',
    NAME: 'Basic'
  },
  BLUE: {
    ID: 'blue',
    NAME: 'Blue'
  },
  BLACK: {
    ID: 'black',
    NAME: 'Black'
  },
  GREEN: {
    ID: 'green',
    NAME: 'Green'
  },
  ORANGE: {
    ID: 'orange',
    NAME: 'Orange'
  },
  CUSTOM: {
    ID: 'custom',
    NAME: 'Custom'
  }
};

export const overrideCssVars = (
  activeTheme: keyof typeof WHITELABEL_THEME_SCHEMA,
  customTheme: Record<CustomThemeKeys, CustomTheme>
): Promise<void> => {
  return new Promise((resolve) => {
    if (activeTheme === THEME_TYPES.CUSTOM.ID) {
      Object.keys(customTheme).forEach((style) => {
        ROOT_ELEMENT.style.setProperty(
          `--${style}`,
          `rgb(${customTheme[style as CustomThemeKeys].r}, ${customTheme[style as CustomThemeKeys].g}, ${
            customTheme[style as CustomThemeKeys].b
          })`
        );
      });
    } else {
      Object.keys(WHITELABEL_THEME_SCHEMA[activeTheme as keyof typeof WHITELABEL_THEME_SCHEMA]).forEach((style) => {
        ROOT_ELEMENT.style.setProperty(`--${style}`, WHITELABEL_THEME_SCHEMA[activeTheme][style]);
      });
    }
    resolve();
  });
};
