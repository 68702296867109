import { addToastAction } from '@adtelligent-fe/react-common/src/components/GlobalToast/store/actions';
import { PAGINATION } from '@adtelligent-fe/react-common/src/constants/PAGINATION';
import { useCreateAdUnitLinkMutation } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-ml-adunit.api';
import { IListQueryParams } from '@adtelligent-fe/react-common/src/typings/http';
import { yupResolver } from '@hookform/resolvers/yup';
import lodash_get from 'lodash/get';
import { Button } from 'primereact/button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import * as yup from 'yup';

import LinkFromColumn from './link-from-column';
import LinkToColumn from './link-to-column';

export type FormValues = {
  from: {
    client: Record<string, any>;
    site?: Record<string, any>;
    adunit?: Record<string, any>;
  };
  to: {
    client: Record<string, any>;
    site?: Record<string, any>;
  };
};

export const clientsRequestParams: IListQueryParams = {
  limit: PAGINATION.PAGE_SIZE,
  where: 'id,name',
  fields: 'id,name'
};
const CreateAdunitForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema)
  });
  const [createAdUnit, { isLoading: isCreateLoading }] = useCreateAdUnitLinkMutation();
  const onSubmit = async (data: FormValues) => {
    const site_id = lodash_get(data, 'to.site.id');
    const adunit_id = lodash_get(data, 'from.adunit.id');

    if (site_id && adunit_id) {
      await createAdUnit({ site_id, adunit_id });
      onCancel();
    } else {
      dispatch(addToastAction([{ severity: 'error', detail: t('form-selection-error-message') }]));
    }
  };
  const onCancel = () => {
    dispatch(push('/ml-adunit-list'));
  };

  return (
    <form className={'border p-2'} onSubmit={form.handleSubmit(onSubmit)}>
      <div className={'container d-flex justify-content-between'}>
        <LinkFromColumn {...form} />
        <LinkToColumn {...form} />
      </div>

      <div className={'d-flex justify-content-end gap-2 mt-2'}>
        <Button type={'button'} outlined label={t('cancel')} onClick={onCancel} />
        <Button disabled={isCreateLoading} label={t('create')} />
      </div>
    </form>
  );
};

export default CreateAdunitForm;
const validationSchema = yup.object({
  from: yup.object({
    adunit: yup.object().shape({}).required()
  }),
  to: yup.object({
    site: yup.object().shape({}).required()
  })
});
