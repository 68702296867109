import { SuspenseFallback } from '@adtelligent-fe/react-common/src/components/SuspenseFallback';
import { usePermissions } from '@adtelligent-fe/react-common/src/hooks/usePermissions';
import { MenuItem } from 'primereact/menuitem';
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useRouteProtection } from '../../modules/app/hooks';
import { ProtectedLayout } from '../ProtectedLayout';

interface IProtectedRoute {
  permissions?: string[];
  contentLayout?: FC;
  sidebarItems?: MenuItem[];
}

export const ProtectedRouteWithLayout: FC<IProtectedRoute> = ({ permissions, contentLayout, sidebarItems }) => {
  const { isPermitted } = usePermissions(permissions);

  const { isAuthenticated } = useRouteProtection();

  if (isAuthenticated === null) {
    return <SuspenseFallback />;
  }

  if (isAuthenticated === true) {
    if (isPermitted === false) {
      return <Navigate to="/" />;
    }

    return <ProtectedLayout contentLayout={contentLayout} sidebarItems={sidebarItems} />;
  }

  return <Navigate to="/auth" />;
};
