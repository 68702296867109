import React from 'react';
import { Helmet } from 'react-helmet';

import CreateAdunitForm from '../components/create-adunit-form';

const CreateAdUnitLinkPage = () => {
  return (
    <>
      <Helmet title={'MCM ML List / Create'} />
      <CreateAdunitForm />
    </>
  );
};

export default CreateAdUnitLinkPage;
