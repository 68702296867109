import { IResponseException } from '@adtelligent-fe/react-common/src/typings/data';

export interface ISignInFormDto {
  username: string;
  password: string;
}

export interface ISignInFormState {
  isLoading: boolean;
  error: IResponseException;
  data: ISignInFormDto;
}

export interface ISignUpFormDto {
  first_name: string;
  last_name: string;
  email: string;
  messenger_type: string;
  messenger_id: string;
  password: string;
  password_repeat: string;
  'g-recaptcha-response': string;
  is_tos_accepted: number;
  opted_in: number;
}

export interface ISignUpFormState {
  isLoading: boolean;
  error: IResponseException;
  data: ISignUpFormDto;
}

export interface IForgotPasswordFormDto {
  email: string;
}

export interface IForgotPasswordFormState {
  isLoading: boolean;
  error: IResponseException;
  data: IForgotPasswordFormDto;
  success: boolean;
}

export interface IResetPasswordFormDto {
  password: string;
  repeat_password: string;
}

export interface IResetPasswordFormState {
  isLoading: boolean;
  error: IResponseException;
  data: IResetPasswordFormDto;
}

export interface IAuthState {
  signInForm: ISignInFormState;
  signUpForm: ISignUpFormState;
  forgotPasswordForm: IForgotPasswordFormState;
  resetPasswordForm: IResetPasswordFormState;
}

export enum AuthActionTypes {
  InitSignInForm = '@@auth/INIT_SIGN_IN_FORM',
  SignIn = '@@auth/SIGN_IN',
  SignInSuccess = '@@auth/SIGN_IN_SUCCESS',
  SignInFailure = '@@auth/SIGN_IN_FAILURE',
  InitSignUpForm = '@@auth/INIT_SIGN_UP_FORM',
  SignUp = '@@auth/SIGN_UP',
  SignUpSuccess = '@@auth/SIGN_UP_SUCCESS',
  SignUpFailure = '@@auth/SIGN_UP_FAILURE',
  InitForgotPasswordForm = '@@auth/INIT_FORGOT_PASSWORD_FORM',
  ForgotPassword = '@@auth/FORGOT_PASSWORD',
  ForgotPasswordSuccess = '@@auth/FORGOT_PASSWORD_SUCCESS',
  ForgotPasswordFailure = '@@auth/FORGOT_PASSWORD_FAILURE',
  ResetPassword = '@@auth/RESET_PASSWORD',
  ResetPasswordSuccess = '@@auth/RESET_PASSWORD_SUCCESS',
  ResetPasswordFailure = '@@auth/RESET_PASSWORD_FAILURE',
  Logout = '@@auth/LOGOUT',
  SignInBySession = '@@auth/SIGN_IN_BY_SESSION'
}
