import { Button } from 'primereact/button';
import React from 'react';

interface IActionButtonComponentProps {
  onClick: () => void;
  icon: string;
  tooltip: string;
}
const ActionButtonComponent = ({ onClick, tooltip, icon }: IActionButtonComponentProps) => {
  return <Button icon={`pi ${icon}`} tooltip={tooltip} onClick={onClick} link />;
};

export default ActionButtonComponent;
