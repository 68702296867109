import { AdtSelect } from '@adtelligent-fe/react-common/src/components/AdtSelect';
import FormRowComponent from '@adtelligent-fe/react-common/src/components/form-row-component';
import { PAGINATION } from '@adtelligent-fe/react-common/src/constants/PAGINATION';
import { useLazyGetAdUnitsListQuery } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-adunit.api';
import { useLazyGetClientsListQuery } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-clients.api';
import { useLazyGetSitesListQuery } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-sites.api';
import { IListQueryParams } from '@adtelligent-fe/react-common/src/typings/http';
import lodash_get from 'lodash/get';
import lodash_set from 'lodash/set';
import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clientsRequestParams, FormValues } from './index';

const LinkFromColumn = ({ control, watch, setValue }: UseFormReturn<FormValues, object>) => {
  const { t } = useTranslation();
  const [getClients, { isFetching: isClientsLoading, data }] = useLazyGetClientsListQuery();
  const [getSites, { isLoading: isSitesLoading }] = useLazyGetSitesListQuery();
  const [getAdUnits, { isLoading: isAdUnitsLoading }] = useLazyGetAdUnitsListQuery();

  const client = watch('from.client');
  const site = watch('from.site');

  const sitesRequestParams: IListQueryParams = useMemo(() => {
    const params: IListQueryParams = {
      limit: PAGINATION.PAGE_SIZE,
      where: 'id,domain',
      fields: 'id,domain'
    };

    if (client) {
      lodash_set(params, 'owner_id', lodash_get(client, 'id', ''));
    }

    return params;
  }, [client]);

  const adunitRequestParams: IListQueryParams = useMemo(() => {
    const params: IListQueryParams = {
      limit: PAGINATION.PAGE_SIZE,
      where: 'id,code',
      fields: 'id,code'
    };

    if (site) {
      lodash_set(params, 'site_id', site);
    }

    return params;
  }, [site]);

  useEffect(() => {
    getClients({ ids: '751204', ...clientsRequestParams });
    if (data) {
      setValue('from.client', data.body[0]);
    }
  }, [data, getClients, setValue]);

  return (
    <div className="col-5 ">
      <div>
        <h5>{t('link-from')}</h5>
      </div>
      <FormRowComponent label={t('client-id')} name={'from.client'}>
        <Controller
          control={control}
          name={'from.client'}
          render={({ field, fieldState }) => (
            <AdtSelect
              {...field}
              onChange={(e) => {
                setValue('from.site', undefined);
                setValue('from.adunit', undefined);
                field.onChange(e);
              }}
              isInvalid={fieldState.invalid}
              optionLabel={'id'}
              requestParams={clientsRequestParams}
              labelTemplate={'#{{id}} {{name}}'}
              getData={getClients}
              isLoading={isClientsLoading}
            />
          )}
        />
      </FormRowComponent>

      <FormRowComponent label={t('site-id')} name={'from.site'}>
        <Controller
          name={'from.site'}
          control={control}
          render={({ field, fieldState }) => (
            <AdtSelect
              {...field}
              onChange={(e) => {
                setValue('from.adunit', undefined);
                field.onChange(e);
              }}
              disabled={!client}
              isInvalid={fieldState.invalid}
              labelTemplate={'#{{id}} {{domain}}'}
              optionValue={'id'}
              optionLabel={'domain'}
              requestParams={sitesRequestParams}
              isLoading={isSitesLoading}
              getData={getSites}
            />
          )}
        />
      </FormRowComponent>

      <FormRowComponent required label={t('ad-unit-id')} name={'from.adunit'}>
        <Controller
          name={'from.adunit'}
          control={control}
          render={({ field, fieldState }) => (
            <AdtSelect
              {...field}
              disabled={!site}
              isInvalid={fieldState.invalid}
              labelTemplate={'#{{id}} {{code}}'}
              optionLabel={'code'}
              requestParams={adunitRequestParams}
              isLoading={isAdUnitsLoading}
              getData={getAdUnits}
            />
          )}
        />
      </FormRowComponent>
    </div>
  );
};

export default LinkFromColumn;
