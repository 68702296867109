import { PAGINATION } from '@adtelligent-fe/react-common/src/constants/PAGINATION';
import { IDefaultGridFilterData } from '@adtelligent-fe/react-common/src/types/common.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GranuleItem } from './components/dfp-granularity/dfp-granularity.types';
import { brandSafetySettingsFieldNames } from './helpers/constants';
import {
  AdminSettingsFormData,
  AdvancedSettingsFormData,
  BasicSettingsFormData,
  BrandSafetySettingsFormData,
  FormPayloadUnion,
  IHBMPSitesFilterData
} from './sites.types';

export interface IHBMPSitesSlice {
  filterData: IHBMPSitesFilterData;
  dfpGranularity: {
    custom_granularity_config: GranuleItem[];
    price_granularity_id: number | undefined;
  };
  gridFilterData: IDefaultGridFilterData;
  siteCreationTabIndex: number;
  basicSettingsTabData: BasicSettingsFormData;
  brandSafetySettingsTabData: BrandSafetySettingsFormData;
  adminSettingsTabData: AdminSettingsFormData;
  advancedSettingsTabData: AdvancedSettingsFormData;
}
export const initialSitesState: IHBMPSitesSlice = {
  gridFilterData: {
    page: 1,
    first: 0,
    rows: PAGINATION.PAGE_SIZE,
    sortField: '',
    sortOrder: 1
  },
  filterData: {
    search: '',
    owner_id: undefined,
    iab_category: [],
    is_test: '0'
  },

  dfpGranularity: {
    custom_granularity_config: [],
    price_granularity_id: undefined
  },
  siteCreationTabIndex: 0,
  basicSettingsTabData: {
    client_id: null,
    domain: '',
    iab_category: null,
    network_id: null
  },
  brandSafetySettingsTabData: {
    [brandSafetySettingsFieldNames.blocked_advertisers]: '',
    [brandSafetySettingsFieldNames.blocked_categories]: []
  },
  adminSettingsTabData: {
    skip_initial_auction: [],
    is_allow_1x1: 0,
    is_enabled_strict_size_matching: 0,
    is_enabled_single_display_mode: 1,
    is_enabled_advanced_refresh_timer: 1,
    is_enabled_throttle_duplicated: 1,
    override_domain: '',
    is_show_bidmatic_logo: 1,
    adx_enhance: '1',
    is_enabled_exclude_from_geoedge: 0,
    is_enabled_refresh_imr: 0,
    is_mobile_refresh_logic_enabled: 0,
    refresh_configs: {
      auctionStartAt: 'display',
      mobile_refresh_on: 'display',
      refreshHBWinnerInterval: '30000',
      refreshSubsequentInterval: '',
      refreshWhenWinnerIs: ['market'],
      buyersInRefresh: ['market'],
      mobile_refresh_interval: '30000',
      mobile_subsequent_refresh_interval: '',
      mobile_refresh_when_buyer_is: ['market'],
      mobile_buyers_in_refresh: ['market'],
      adunits_excluded_from_refresh: undefined
    },
    hb_creative_ids: '',
    clients_direct_line_items: '',
    last_google_sync_timestamp: '',
    is_manual_refresh: 0
  },
  advancedSettingsTabData: {
    auction_timeout: '2000',
    is_intentiq_enabled: 0,
    is_client_marketplace_enabled: 1,
    is_lazy_load_enabled: 1,
    is_bidmatic_ad_units_enabled: 1,
    is_other_ad_units_enabled: 1,
    screens_desktop: '1',
    screens_mobile: '2',
    is_refresh_logic_enabled: 0,
    refresh_logic_config: {
      refresh_limit: '50',
      is_refresh_with_gam_enabled: 1,
      is_only_viewable_refresh_enabled: 1,
      timeout_after: '30000'
    },
    exclude_gam_line_items_from_refresh: '',
    is_enabled_ab_strategies: 0,
    cmp_enabled: 0,
    cmp_timeout: '1000'
  }
};

const sitesSlice = createSlice({
  name: 'hbmpSitesSlice',
  initialState: initialSitesState,
  reducers: {
    clearFormData: (state) => {
      state.basicSettingsTabData = initialSitesState.basicSettingsTabData;
      state.brandSafetySettingsTabData = initialSitesState.brandSafetySettingsTabData;
      state.adminSettingsTabData = initialSitesState.adminSettingsTabData;
      state.advancedSettingsTabData = initialSitesState.advancedSettingsTabData;
      state.siteCreationTabIndex = 0;
    },
    initFormData: (state, action) => {
      const data = action.payload;

      const basicSettingsValues: BasicSettingsFormData = { ...initialSitesState.basicSettingsTabData };
      const brandSafetySettingsValues: BrandSafetySettingsFormData = {
        ...initialSitesState.brandSafetySettingsTabData
      };
      const advancedSettingsValues: AdvancedSettingsFormData = { ...initialSitesState.advancedSettingsTabData };
      const adminSettingsValues: AdminSettingsFormData = { ...initialSitesState.adminSettingsTabData };

      for (const key in data) {
        if (key in basicSettingsValues) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          basicSettingsValues[key] = data[key];
        } else if (key in brandSafetySettingsValues) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          brandSafetySettingsValues[key] = data[key];
        } else if (key in adminSettingsValues) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          adminSettingsValues[key] = data[key];
        } else if (key in advancedSettingsValues) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          advancedSettingsValues[key] = data[key];
        }
      }

      state.basicSettingsTabData = { ...state.basicSettingsTabData, ...basicSettingsValues };
      state.brandSafetySettingsTabData = { ...state.brandSafetySettingsTabData, ...brandSafetySettingsValues };
      state.adminSettingsTabData = { ...state.adminSettingsTabData, ...adminSettingsValues };
      state.advancedSettingsTabData = { ...state.advancedSettingsTabData, ...advancedSettingsValues };
    },
    setFormData: (state, action: PayloadAction<FormPayloadUnion>) => {
      const { name, values } = action.payload;

      switch (name) {
        case 'basicSettingsTabData':
          state.basicSettingsTabData = values;
          break;
        case 'brandSafetySettingsTabData':
          state.brandSafetySettingsTabData = values;
          break;
        case 'adminSettingsTabData':
          state.adminSettingsTabData = values;
          break;
        case 'advancedSettingsTabData':
          state.advancedSettingsTabData = values;
          break;
      }
    },
    setSiteCreationTab: (state, action: PayloadAction<number>) => {
      state.siteCreationTabIndex = action.payload;
    },
    setPriceGranularityId: (state, action: PayloadAction<number>) => {
      state.dfpGranularity.price_granularity_id = action.payload;
    },
    setGranules: (state, action: PayloadAction<GranuleItem[]>) => {
      state.dfpGranularity.custom_granularity_config = action.payload;
    },
    setGranule: (state, action: PayloadAction<GranuleItem>) => {
      state.dfpGranularity.custom_granularity_config = state.dfpGranularity.custom_granularity_config.concat(
        action.payload
      );
    },
    deleteGranule: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      state.dfpGranularity.custom_granularity_config = state.dfpGranularity.custom_granularity_config.filter(
        (item, itemIndex) => itemIndex !== index
      );
    },
    setSearchParams: (state, action: PayloadAction<Record<string, any>>) => {
      const gridParams: Partial<IDefaultGridFilterData> = {};
      const filterParams: Partial<IHBMPSitesFilterData> = {};

      for (const key in action.payload) {
        if (key in state.gridFilterData) {
          gridParams[key] = action.payload[key];
        } else if (key in state.filterData) {
          filterParams[key as keyof IHBMPSitesFilterData] = action.payload[key];
        }
      }
      state.gridFilterData = { ...state.gridFilterData, ...gridParams };
      state.filterData = { ...state.filterData, ...filterParams };
    },
    setGridFilterData: (state, action: PayloadAction<IDefaultGridFilterData>) => {
      state.gridFilterData = { ...state.gridFilterData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = { ...state.filterData, ...action.payload };
    }
  }
});

export const {
  initFormData,
  setSearchParams,
  setGridFilterData,
  setPriceGranularityId,
  deleteGranule,
  setGranules,
  setGranule,
  setFilterData,
  setSiteCreationTab,
  setFormData,
  clearFormData
} = sitesSlice.actions;

export default sitesSlice.reducer;
