export const APP_EXPAND = [
  'account_manager',
  'cs_manager',
  'sales_manager',
  'pricing',
  'domain_lists',
  'bundle_lists',
  'application_lists',
  'is_adtrader_info_complete',
  'currency',
  'settings',
  'campaigns_request_limits',
  'trackers'
].join();
export const APP_IDONLY = ['domain_lists', 'application_lists', 'bundle_lists'].join();
