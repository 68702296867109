import { articlesApi } from '@adtelligent-fe/react-common/src/services/aiVideo/articles/articles.api';
import { sitesApi } from '@adtelligent-fe/react-common/src/services/aiVideo/sites/sites.api';
import { socialMediaApi } from '@adtelligent-fe/react-common/src/services/aiVideo/social-media.api';
import { videoTemplateApi } from '@adtelligent-fe/react-common/src/services/aiVideo/video-templates/video-template.api';
import { clientApi } from '@adtelligent-fe/react-common/src/services/ssp/clients/client.api';
import { injectReducer } from '@adtelligent-fe/react-common/src/store';
import { injectMiddlewares } from '@adtelligent-fe/react-common/src/utils/injectMiddlewares';

import AiVideoPage from './ai-video.page';

injectReducer(articlesApi.reducerPath, articlesApi.reducer);
injectReducer(sitesApi.reducerPath, sitesApi.reducer);
injectReducer(videoTemplateApi.reducerPath, videoTemplateApi.reducer);
injectReducer(clientApi.reducerPath, clientApi.reducer);
injectReducer(socialMediaApi.reducerPath, socialMediaApi.reducer);

injectMiddlewares([
  clientApi.middleware,
  articlesApi.middleware,
  sitesApi.middleware,
  videoTemplateApi.middleware,
  socialMediaApi.middleware
]);
export default AiVideoPage;
