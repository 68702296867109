import CustomDialogComponent from '@adtelligent-fe/react-common/src/components/CustomDialog/custom-dialog.component';
import { GlobalToast } from '@adtelligent-fe/react-common/src/components/GlobalToast';
import { ServicePageContainer } from '@adtelligent-fe/react-common/src/components/ServicePageContainer';
import { SuspenseFallback } from '@adtelligent-fe/react-common/src/components/SuspenseFallback';
import { AuthActions } from '@adtelligent-fe/react-common/src/modules/auth';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useFavicon } from 'primereact/hooks';
import React, { FC, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, Route, Routes, useSearchParams } from 'react-router-dom';

import { logoutAction } from '../../lib/auth/store/actions';
import AiVideoPage from '../ai-video';
import MlAuditsPage from '../ml-adunits-list';
import Sites from '../sites';
import { initAppActions } from './store/actions';
import { fetchUserDataEffect } from './store/effects';
import { overrideCssVars } from './utils/override-css-vars';

const Page404 = React.lazy(() => import('@adtelligent-fe/react-common/src/components/Page404'));
const Auth = React.lazy(() => import('../../lib/auth'));
const MainPage = React.lazy(() => import('./pages/MainPage'));
const Clients = React.lazy(() => import('../clients'));
const Publishers = React.lazy(() => import('../publishers'));
const GlobalSettings = React.lazy(() => import('../global-settings'));
const SellersPage = React.lazy(() => import('../sellers'));
// const AiVideoPage = React.lazy(() => import('../ai-video'));

export const App: FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionId = searchParams.get('sessionId');

    dispatch(initAppActions());

    if (sessionId) {
      dispatch(AuthActions.signInBySessionAction(sessionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFavicon('/assets/images/bidmatic-favicon.ico', 'icon');

  overrideCssVars('bidmatic-blue', {} as any).catch(console.error);

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route element={<AppWrapper />}>
          <Route path={'/ml-adunit-list/*'} element={<MlAuditsPage />} />
          <Route path={'/clients/*'} element={<Clients />} />
          <Route path={'/sites/*'} element={<Sites />} />
          <Route path={'/publishers/*'} element={<Publishers />} />
          <Route path={'/global-settings/*'} element={<GlobalSettings />} />
          <Route path={'/sellers/*'} element={<SellersPage />} />
          <Route path={'/ai-video/*'} element={<AiVideoPage />} />
          <Route path="/" element={<MainPage />} />
        </Route>

        <Route
          path="*"
          element={
            <ServicePageContainer>
              <Page404 />
            </ServicePageContainer>
          }
        />
      </Routes>
      <GlobalToast />
      {/*<AdBlock />*/}
      {/*<UnsupportedBrowser />*/}
      {/*<GdprCheck />*/}
      <ConfirmDialog />
      <CustomDialogComponent />
    </Suspense>
  );
};

const AppWrapper: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserDataEffect().catch((e) => {
      console.log('App wrapper logout');
      dispatch(logoutAction());
    });
  }, [dispatch]);

  return <Outlet />;
};
