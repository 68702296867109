import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as appActions from './actions';
import { IAppState } from './types';

export type TAppActions = ActionType<typeof appActions>;

export const initialState: IAppState = {
  isLoading: false
};

export const appReducer: Reducer<IAppState, TAppActions> = (state = initialState, action: TAppActions) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
