import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './dictionaries/en';
import translationRU from './dictionaries/ru';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['cookie']
    },
    resources: {
      en: {
        translation: translationEN
      },
      ru: {
        translation: translationRU
      }
    },
    supportedLngs: ['ru', 'en']
  });

export default i18n;
