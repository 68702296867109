import { toQueryString } from '@adtelligent-fe/react-common/src/utils/objectUtils';
import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { push } from 'redux-first-history';

import { useHBSelector } from '../../../store/types';
import AdunitGrid from '../components/adunit-grid';
import { getPreparedFilterQueryParams, getPreparedFilterQueryParamsFromRoute } from '../helpers/utils';
import { setFilterData } from '../ml-adunits.slice';

const MlAdunitsListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterData = useHBSelector((s) => s.mlAdunitsSlice.filterData);

  const [searchParams] = useSearchParams();
  const queryParams = getPreparedFilterQueryParamsFromRoute(searchParams);

  useEffect(() => {
    dispatch(setFilterData(queryParams));
  }, [dispatch]);
  useEffect(() => {
    const queryString = toQueryString(getPreparedFilterQueryParams(filterData));

    dispatch(push({ search: `?${queryString}` }));
  }, [dispatch, filterData]);

  return (
    <>
      <Helmet title={t('mcm-ml-list')} />
      <div>
        <div className={'mb-2 d-flex justify-content-end'}>
          <Button label={t('create')} onClick={() => dispatch(push('/ml-adunit-list/create'))} />
        </div>
        <AdunitGrid />
      </div>
    </>
  );
};

export default MlAdunitsListPage;
