export { App } from './app';
import { injectReducer, injectSagas } from '@adtelligent-fe/react-common/src/store';

import { appReducer } from './store/reducers';
import { appSaga } from './store/sagas';
import * as AppTypes from './store/types';

injectReducer('app', appReducer);
injectSagas(appSaga);

export { AppTypes };
