import { GLOBAL_ACTION_TYPES } from '@adtelligent-fe/react-common/src/constants/GLOBAL_ACTION_TYPES';
import { IClientData } from '@adtelligent-fe/react-common/src/services/ssp/clients/clientApiService';
import { IResponseException } from '@adtelligent-fe/react-common/src/typings/data';
import { action } from 'typesafe-actions';

export const initAppActions = () => action(GLOBAL_ACTION_TYPES.INIT_APP);
export const fetchUserDataSuccessAction = (data: IClientData) =>
  action(GLOBAL_ACTION_TYPES.FETCH_USER_DATA_SUCCESS, data);
export const fetchUserDataFailureAction = (errorMsg: IResponseException) =>
  action(GLOBAL_ACTION_TYPES.FETCH_USER_DATA_FAILURE, errorMsg);
