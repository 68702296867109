import { clearObjEmptyVal } from '@adtelligent-fe/react-common/src/utils/objectUtils';
import lodash_get from 'lodash/get';
import lodash_keys from 'lodash/keys';

import { initialMlAdunitLinksState } from '../ml-adunits.slice';

const INT_FIELDS = ['page', 'first', 'rows'];

export const getPreparedFilterQueryParams = (payload) => {
  return clearObjEmptyVal({
    page: lodash_get(payload, 'page', initialMlAdunitLinksState.filterData.page),
    first: lodash_get(payload, 'first', initialMlAdunitLinksState.filterData.first),
    rows: lodash_get(payload, 'rows', initialMlAdunitLinksState.filterData.rows)
  });
};

export const getPreparedFilterQueryParamsFromRoute = (query: URLSearchParams) => {
  const availableFilterKeys = lodash_keys(initialMlAdunitLinksState.filterData);
  const queryParams = {};

  query.forEach((value, key) => {
    if (availableFilterKeys.includes(key)) {
      if (INT_FIELDS.includes(key)) {
        queryParams[key] = +(value || lodash_get(initialMlAdunitLinksState, `filterData[${key}]`));
      } else {
        queryParams[key] = value || lodash_get(initialMlAdunitLinksState, `filterData[${key}]`);
      }
    }
  });

  return queryParams;
};
