import { AdtSelect } from '@adtelligent-fe/react-common/src/components/AdtSelect';
import FormRowComponent from '@adtelligent-fe/react-common/src/components/form-row-component';
import { PAGINATION } from '@adtelligent-fe/react-common/src/constants/PAGINATION';
import { useLazyGetClientsListQuery } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-clients.api';
import { useLazyGetSitesListQuery } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-sites.api';
import lodash_get from 'lodash/get';
import lodash_set from 'lodash/set';
import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clientsRequestParams, FormValues } from './index';

const LinkToColumn = ({ watch, control, setValue }: UseFormReturn<FormValues, object>) => {
  const { t } = useTranslation();
  const [getClients, { isFetching: isClientsLoading }] = useLazyGetClientsListQuery();
  const [getSites, { isLoading: isSitesLoading }] = useLazyGetSitesListQuery();

  const client = watch('to.client');

  const sitesRequestParams = useMemo(() => {
    const params = {
      limit: PAGINATION.PAGE_SIZE,
      where: 'id,domain',
      fields: 'id,domain'
    };

    if (client) {
      lodash_set(params, 'owner_id', lodash_get(client, 'id', ''));
    }

    return params;
  }, [client]);

  return (
    <div className=" col-5">
      <div>
        <h5>{t('link-to')}</h5>
      </div>

      <FormRowComponent label={t('client-id')} name={'to.client'}>
        <Controller
          control={control}
          name={'to.client'}
          render={({ field, fieldState }) => (
            <AdtSelect
              {...field}
              onChange={(e) => {
                setValue('to.site', undefined);
                field.onChange(e);
              }}
              isInvalid={fieldState.invalid}
              optionLabel={'id'}
              requestParams={clientsRequestParams}
              labelTemplate={'#{{id}} {{name}}'}
              getData={getClients}
              isLoading={isClientsLoading}
            />
          )}
        />
      </FormRowComponent>

      <FormRowComponent required label={t('site-id')} name={'to.site'}>
        <Controller
          name={'to.site'}
          control={control}
          render={({ field, fieldState }) => (
            <AdtSelect
              {...field}
              disabled={!client}
              isInvalid={fieldState.invalid}
              labelTemplate={'#{{id}} {{domain}}'}
              optionLabel={'domain'}
              requestParams={sitesRequestParams}
              isLoading={isSitesLoading}
              getData={getSites}
            />
          )}
        />
      </FormRowComponent>
    </div>
  );
};

export default LinkToColumn;
