import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';

import { ProtectedRouteWithLayout } from '../../components/ProtectedRoute';
import { ARTICLE_ROUTES } from './modules/articles/helpers/constants';
import { SITE_ROUTES } from './modules/sites/helpers/constants';
import { VIDEO_TEMPLATE_ROUTES } from './modules/video-template/helpers/constants';

const VideoTemplatePage = React.lazy(() => import('./modules/video-template'));
const ArticlesPage = React.lazy(() => import('./modules/articles'));
const SitesPage = React.lazy(() => import('./modules/sites'));

const AiVideoPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items: MenuItem[] = [
    {
      label: t('sites'),
      url: SITE_ROUTES.SITES,
      command: () => {
        dispatch(push(SITE_ROUTES.SITES));
      }
    },
    {
      label: t('articles'),
      url: ARTICLE_ROUTES.ARTICLES,
      command: () => {
        dispatch(push(ARTICLE_ROUTES.ARTICLES));
      }
    },
    {
      label: t('video-templates'),
      url: VIDEO_TEMPLATE_ROUTES.TEMPLATES,
      command: () => {
        dispatch(push(VIDEO_TEMPLATE_ROUTES.TEMPLATES));
      }
    }
  ];

  return (
    <Routes>
      <Route path="/" element={<ProtectedRouteWithLayout sidebarItems={items} />}>
        <Route path={'articles/*'} element={<ArticlesPage />} />
        <Route path={'site/*'} element={<SitesPage />} />
        <Route path={'video-template/*'} element={<VideoTemplatePage />} />
        <Route index element={<Navigate to={'articles'} />} />
      </Route>
    </Routes>
  );
};

export default AiVideoPage;
