import {
  useDeleteAdUnitMutation,
  useGetAdUnitLinksListQuery,
  useLazyGetAdUnitLinksListQuery
} from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-ml-adunit.api';
import { IListQueryParams } from '@adtelligent-fe/react-common/src/typings/http';

import { IDefaultGridFilterData } from '../ml-adunits.types';

export default function useAdunitLinks(filterData: IDefaultGridFilterData) {
  const getListRequestParams = () => {
    const requestParams: IListQueryParams = {
      expand: 'site,adunit',
      page: filterData.page,
      limit: filterData.rows
    };

    if (filterData.sortField) {
      requestParams.sort = `${filterData.sortOrder === -1 ? '-' : ''}${filterData.sortField}`;
    }

    return requestParams;
  };

  const [getAdunitLinksList, { isFetching: isLazyAdunitLinksListLoading }] = useLazyGetAdUnitLinksListQuery();
  const [deleteAdUnit, { isLoading: isDeleteLoading }] = useDeleteAdUnitMutation();
  const { data: adUnitsLinkList, isFetching: isAdunitListFetching } = useGetAdUnitLinksListQuery(
    getListRequestParams()
  );

  const isLoading = isDeleteLoading || isAdunitListFetching || isLazyAdunitLinksListLoading;

  return {
    getAdunitLinksList,
    adUnitsLinkList,
    deleteAdUnit,
    isLoading
  };
}
