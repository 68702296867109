import ListDataTableComponent from '@adtelligent-fe/react-common/src/components/ListDataTable/list-data-table.component';
import { hbmpMlAdunitApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-ml-adunit.api';
import lodash_get from 'lodash/get';
import lodash_omit from 'lodash/omit';
import { ColumnProps } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTableStateEvent } from 'primereact/datatable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ActionButtonComponent from '../../../components/action-button.component';
import ActionCellContainerComponent from '../../../components/action-cell-container.component';
import { useHBSelector } from '../../../store/types';
import useAdunitLinks from '../hooks/adunit-links.hook';
import { setFilterData } from '../ml-adunits.slice';

const AdunitGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterData = useHBSelector((s) => s.mlAdunitsSlice.filterData);

  const { isLoading, adUnitsLinkList, deleteAdUnit } = useAdunitLinks(filterData);
  const onFilter = (e: DataTableStateEvent) => {
    const payload = lodash_omit(e, 'filters');

    if (!isNaN(payload.page)) {
      payload.page = lodash_get(payload, 'page', 1) + 1;
    }
    dispatch(setFilterData(payload));
  };

  const onRefreshTable = () => {
    dispatch(hbmpMlAdunitApi.util.invalidateTags(['ADUNITS_LIST']));
  };
  const onDelete = (id: number) => {
    confirmDialog({
      position: 'top',
      accept: () => deleteAdUnit(id),
      message: () => (
        <>
          {t('delete-confirmation-message')} <strong>Ad Unit: {id}</strong> ?
        </>
      ),
      header: t('confirmation')
    });
  };

  const columns: ColumnProps[] = [
    {
      field: 'id',
      header: 'ID',
      style: { width: '50px' },
      sortable: true
    },
    {
      field: 'site_id',
      header: t('site-id'),
      sortable: true
    },
    {
      field: 'site.domain',
      header: t('site-name')
    },
    {
      field: 'adunit_id',
      header: t('ad-unit-id'),
      sortable: true
    },
    {
      field: 'adunit.code',
      header: t('ad-unit-name')
    },
    {
      field: 'action',
      header: t('actions'),
      style: { width: '100px' },
      frozen: true,
      alignFrozen: 'right',
      body: (rowData) => {
        return (
          <ActionCellContainerComponent>
            <ActionButtonComponent icon={'pi-trash'} tooltip={t('delete')} onClick={() => onDelete(rowData.id)} />
          </ActionCellContainerComponent>
        );
      }
    }
  ];

  return (
    <div>
      <ListDataTableComponent
        onFilter={onFilter}
        isLoading={isLoading}
        data={lodash_get(adUnitsLinkList, 'body', [])}
        refreshDataHandler={onRefreshTable}
        filterData={filterData}
        totalRecords={lodash_get(adUnitsLinkList, 'pagination.totalCount', 0)}
        columns={columns}
      />
    </div>
  );
};

export default AdunitGrid;
