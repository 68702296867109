import { hbmpDfpNetworkApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-dfp-network.api';
import { hbmpDictionariesApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-dictionaries.api';
import { hbmpPublishersApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-publishers.api';
import { hbmpSitesApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-sites.api';
import { clientApi } from '@adtelligent-fe/react-common/src/services/ssp/clients/client.api';
import { publishersApi } from '@adtelligent-fe/react-common/src/services/ssp/clients/publishers.api';
import { injectReducer } from '@adtelligent-fe/react-common/src/store';
import { injectMiddlewares } from '@adtelligent-fe/react-common/src/utils/injectMiddlewares';

import SitesPage from './sites.page';
import sitesSlice from './sites.slice';

injectReducer('hbmpSitesSlice', sitesSlice);

injectReducer(hbmpSitesApi.reducerPath, hbmpSitesApi.reducer);
injectReducer(hbmpPublishersApi.reducerPath, hbmpPublishersApi.reducer);
injectReducer(publishersApi.reducerPath, publishersApi.reducer);
injectReducer(hbmpDictionariesApi.reducerPath, hbmpDictionariesApi.reducer);
injectReducer(hbmpDfpNetworkApi.reducerPath, hbmpDfpNetworkApi.reducer);
injectReducer(clientApi.reducerPath, clientApi.reducer);
injectMiddlewares([
  clientApi.middleware,
  hbmpSitesApi.middleware,
  hbmpPublishersApi.middleware,
  hbmpDictionariesApi.middleware,
  hbmpDfpNetworkApi.middleware,
  publishersApi.middleware
]);
export default SitesPage;
