import { HTTP_STATUS } from '@adtelligent-fe/react-common/src/constants/HTTP_STATUS';
import { getItem as getClientData } from '@adtelligent-fe/react-common/src/services/ssp/clients/clientApiService';
import { getAuthData } from '@adtelligent-fe/react-common/src/services/storage/indexedDb/userDataTable';
import lodash_get from 'lodash/get';

import { APP_EXPAND, APP_IDONLY } from '../services/constants/FIELD_LIST';

export const fetchUserDataEffect = async () => {
  const authData = await getAuthData();
  const userId = lodash_get(authData, 'sessionData.uid');

  if (userId) {
    const response = await getClientData(userId, {
      params: {
        expand: APP_EXPAND,
        idonly: APP_IDONLY
      },
      data: { ignoreCancelToken: true }
    });

    return response.data.data;
  }

  return Promise.reject({ response: { statusCode: HTTP_STATUS.UNAUTHORIZED, data: 'No auth data' } });
};
