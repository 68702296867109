import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRouteWithLayout } from '../../components/ProtectedRoute';
import CreateAdunitLinkPage from './pages/create-adunit-link.page';
import MlAdunitsListPage from './pages/ml-adunits-list.page';

const MlAuditsPage = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<ProtectedRouteWithLayout />}>
        <Route path={'create'} element={<CreateAdunitLinkPage />} />
        <Route index element={<MlAdunitsListPage />} />
      </Route>
    </Routes>
  );
};

export default MlAuditsPage;
