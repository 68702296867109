import { AuthService } from '@adtelligent-fe/react-common/src/modules/auth';
import { useEffect, useState } from 'react';

export const useRouteProtection = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    let isComponentActive = true;

    AuthService.isUserLoggedIn().then((isLoggedIn) => {
      if (isComponentActive) {
        setIsAuthenticated(isLoggedIn);
      }
    });

    return () => {
      isComponentActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAuthenticated };
};
