import { KnowledgeBase } from '@adtelligent-fe/react-common/src/components/KnowledgeBase';
import { UserInfo } from '@adtelligent-fe/react-common/src/components/UserInfo';
import { AuthActions } from '@adtelligent-fe/react-common/src/modules/auth';
import classNames from 'classnames';
import { Menubar } from 'primereact/menubar';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { APP_LOGO_PATH } from '../../modules/app/constants/app-logo';
import styles from './header-menu.module.scss';

export const HeaderMenu: FC = () => {
  const { t } = useTranslation();
  const [knowledgeBaseVisible, setKnowledgeBaseVisible] = useState(false);
  const dispatch = useDispatch();
  const itemTemplate = (item: MenuItem, options: MenuItemOptions) => {
    return (
      <a
        href={item.url}
        className={classNames(options.className, {
          active: item.url && location.pathname.includes(item.url)
        })}
        target={item.target}
        onClick={(e) => {
          if (item.command) {
            e.preventDefault();
          }

          options.onClick(e);
        }}
        data-pr-tooltip={item.label}
      >
        <span className={options.labelClassName}>{item.label}</span>
      </a>
    );
  };
  const items: MenuItem[] = [
    {
      label: t('MCM MI list'),
      url: '/ml-adunit-list',
      command: () => {
        dispatch(push('/ml-adunit-list'));
      },
      template: itemTemplate
    },
    {
      label: t('clients'),
      url: '/clients',
      command: () => {
        dispatch(push('/clients'));
      },
      template: itemTemplate
    },
    {
      label: t('publishers'),
      url: '/publishers',
      command: () => {
        dispatch(push('/publishers'));
      },
      template: itemTemplate
    },
    {
      label: t('sites'),
      url: '/sites',
      command: () => {
        dispatch(push('/sites'));
      },
      template: itemTemplate
    },
    {
      label: t('global-settings'),
      template: itemTemplate,
      command: () => {
        dispatch(push('/global-settings'));
      },
      url: '/global-settings'
    },
    {
      label: t('sellers'),
      url: '/sellers',
      command: () => {
        dispatch(push('/sellers'));
      },
      template: itemTemplate
    },
    {
      label: t('AI Video'),
      url: '/ai-video',
      command: () => {
        dispatch(push('/ai-video'));
      },
      template: itemTemplate
    }
  ];
  const start = (
    <div className={classNames(styles.start)}>
      <img alt={'App logo'} src={APP_LOGO_PATH} />
    </div>
  );

  const end = (
    <div className={classNames(styles.endWrapper)}>
      <UserInfo className="d-none d-md-flex align-items-center me-3" />
      <div className={classNames(styles.icons)}>
        {/*<AppNotifications icon="pi pi-bell" socketUrl={getCompiledServiceLink(API_ENDPOINT_KEY.NOTIFICATION_SOCKET)} />*/}
        <i onClick={() => dispatch(push('/settings'))} className="pi pi-cog"></i>
        <i onClick={() => setKnowledgeBaseVisible(true)} className="pi pi-question-circle"></i>
        <i className={'pi pi-sign-out'} onClick={() => dispatch(AuthActions.logoutAction())} />
      </div>
    </div>
  );

  return (
    <>
      <Menubar model={items} start={start} end={end} className={styles.menuBar} />
      <KnowledgeBase isVisible={knowledgeBaseVisible} setVisible={setKnowledgeBaseVisible} />
    </>
  );
};
