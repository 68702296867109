import { GLOBAL_ACTION_TYPES } from '@adtelligent-fe/react-common/src/constants/GLOBAL_ACTION_TYPES';
import { AuthTypes } from '@adtelligent-fe/react-common/src/modules/auth';
import { IClientData } from '@adtelligent-fe/react-common/src/services/ssp/clients/clientApiService';
import { setProfileData } from '@adtelligent-fe/react-common/src/services/storage/indexedDb/userDataTable';
import { parseResponseException } from '@adtelligent-fe/react-common/src/utils/responseUtils';
import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchUserDataFailureAction, fetchUserDataSuccessAction } from './actions';
import { fetchUserDataEffect } from './effects';

function* fetchUserDataSaga() {
  try {
    const result: IClientData = yield call(fetchUserDataEffect);

    yield call(setProfileData, result);
    yield put(fetchUserDataSuccessAction(result));
  } catch (err) {
    yield put(fetchUserDataFailureAction(parseResponseException(err as Error)));
  }
}

export const appSaga = [
  takeLatest(GLOBAL_ACTION_TYPES.INIT_APP, fetchUserDataSaga),
  takeLatest(AuthTypes.AuthActionTypes.SignInSuccess, fetchUserDataSaga)
];
