import { rtkQueryErrorLogger } from '@adtelligent-fe/react-common/src/services/aiVideo/ai-video.base-api';
import { hbmpAdunitApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-adunit.api';
import { hbmpClientsApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-clients.api';
import { hbmpMlAdunitApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-ml-adunit.api';
import { hbmpSitesApi } from '@adtelligent-fe/react-common/src/services/hbmp/hbmp-sites.api';
import { injectReducer } from '@adtelligent-fe/react-common/src/store';
import { injectMiddlewares } from '@adtelligent-fe/react-common/src/utils/injectMiddlewares';

import mlAdunitsSlice from './ml-adunits.slice';
import MlAuditsPage from './ml-audits.page';

injectReducer('mlAdunitsSlice', mlAdunitsSlice);
injectReducer(hbmpClientsApi.reducerPath, hbmpClientsApi.reducer);
injectReducer(hbmpSitesApi.reducerPath, hbmpSitesApi.reducer);
injectReducer(hbmpAdunitApi.reducerPath, hbmpAdunitApi.reducer);
injectReducer(hbmpMlAdunitApi.reducerPath, hbmpMlAdunitApi.reducer);
injectMiddlewares([
  hbmpClientsApi.middleware,
  hbmpSitesApi.middleware,
  hbmpAdunitApi.middleware,
  hbmpMlAdunitApi.middleware,
  rtkQueryErrorLogger
]);
export default MlAuditsPage;
